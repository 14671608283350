import { GA4Events } from 'shared/enums/GA4Events';
import { GATrack } from 'shared/hooks/useGA';
import { IFormTemplate } from 'shared/interfaces/IFormTemplate';
import { ClinicPetParentLookupResponse, ClinicWidgetRequestTypeResponse } from 'shared/types';
import { WidgetPetParentFragment, WidgetRequestType } from 'shared/types/graphql';
import { create } from 'zustand';
import { AuthenticationState, defaultAuthState } from './types/authentication';
import { defaultDirectBookingState, DirectBookingState } from './types/directBooking';
import { defaultTeletriageState, MembershipStatus, TeletriageState } from './types/teletriage';
import { InitializeState } from './types/initialize';
import {
  advancedRxRefillSteps,
  authenticationSteps,
  defaultRequestSteps,
  directBookingSteps,
  RequestStep,
  teletriageSteps,
} from './types/steps';
import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import { isUndefined } from 'lodash';

interface State {
  clinicId: string;
  clinicName?: string;
  clinicPhone?: string | null;
  clinicEmail?: string | null;
  parentUrl: string;
  petParentId: string | null;
  isOpen: boolean;
  isMobile: boolean;
  isInline: boolean;
  showPreview: boolean;
  isPetPortal: boolean;
  selectedRequest?: ClinicWidgetRequestTypeResponse;
  authentication: AuthenticationState;
  clinicPetParent: ClinicPetParentLookupResponse | WidgetPetParentFragment | null;
  requestSteps: RequestStep[];
  currentStepIndex: number;
  formSubmission?: IFormTemplate;
  directBooking: DirectBookingState;
  teletriage: TeletriageState;
}

interface Action {
  onToggle: (shouldOpen?: boolean) => void;
  initialize: (data: InitializeState) => void;
  setSelectedRequest: (selectedRequest?: ClinicWidgetRequestTypeResponse) => void;
  goToStep: (stepName: RequestStep) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goBackToMainMenu: () => void;
  updateWidgetState: (newState: Partial<State>) => void;
  updateWidgetAuthState: (newAuthState: Partial<AuthenticationState>) => void;
  handleNewUser: () => void;
  handleInactiveUser: () => void;
  handleAuthenticatedUser: () => void;
  updateDbState: (newDbState: Partial<DirectBookingState>) => void;
  handleCreateNewDbUser: () => void;
  updateTeletriageState: (newDbState: Partial<TeletriageState>) => void;
  handleChangeUser: () => void;
  handleNewUserNotAllowed: () => void;
  handleNoPetsNotAllowed: () => void;
  handleUnenrolledUser: (membershipState: MembershipStatus) => void;
  handleEnrolledUser: () => void;
}

const initialState: State = {
  clinicId: '',
  clinicName: '',
  parentUrl: '',
  isOpen: false,
  showPreview: true,
  isMobile: false,
  isInline: false,
  isPetPortal: false,
  clinicPetParent: null,
  petParentId: null,
  teletriage: defaultTeletriageState,
  directBooking: defaultDirectBookingState,
  authentication: defaultAuthState,
  requestSteps: authenticationSteps,
  currentStepIndex: 0,
};

export const useWidgetStore = create<State & Action>()((set) => ({
  ...initialState,
  onToggle: (shouldOpen?: boolean): void => {
    if (shouldOpen !== undefined) {
      shouldOpen ? set(() => ({ isOpen: true })) : set(() => ({ isOpen: false }));
    } else {
      set((state) => (state.isOpen ? { isOpen: false } : { isOpen: true }));
    }
    set(() => ({ showPreview: false }));
  },
  initialize: (options: InitializeState): void => {
    set((state) => {
      return {
        ...state,
        ...(!isUndefined(options.clinicId) && { clinicId: options.clinicId }),
        ...(!isUndefined(options.parentUrl) && { parentUrl: options.parentUrl }),
        ...(!isUndefined(options.isMobile) && { isMobile: options.isMobile }),
        ...(!isUndefined(options.isInline) && { isInline: options.isInline }),
        ...(!isUndefined(options.isPetPortal) && { isPetPortal: options.isPetPortal }),
        ...(!isUndefined(options.isOpen) && { isOpen: options.isOpen }),
        ...(!isUndefined(options.showPreview) && { showPreview: options.showPreview }),
        ...(!isUndefined(options.petParentId) && { petParentId: options.petParentId }),
      };
    });
  },
  setSelectedRequest: (request?: ClinicWidgetRequestTypeResponse): void => {
    set(({ directBooking: { isOtcEnabled, canCreateClientPatient }, isPetPortal, clinicId }) => {
      let formSteps: RequestStep[] = [...authenticationSteps];

      // Do not render contact info step in pet portal
      if (isPetPortal) {
        formSteps = formSteps.filter((step) => step !== 'ContactInfo');
      }

      switch (request?.requestType) {
        case WidgetRequestType.AdvancedRequestRxRefill:
          formSteps = [...formSteps, ...advancedRxRefillSteps];
          break;
        case WidgetRequestType.DirectBooking:
          formSteps = [...formSteps, ...directBookingSteps];
          break;
        case WidgetRequestType.Teletriage:
          formSteps = [...formSteps, ...teletriageSteps];
          break;
        default:
          formSteps = [...formSteps, ...defaultRequestSteps];
          break;
      }

      // Do not include one time code step if:
      // - Otc setting is not enabled for direct booking
      // - Or if selected request type is not direct booking
      // - Or if the widget is being rendered in the pet portal
      const shouldSkipOtcStep =
        !isOtcEnabled || request?.requestType !== WidgetRequestType.DirectBooking || isPetPortal;
      if (shouldSkipOtcStep) {
        formSteps = formSteps.filter((step) => step !== 'OneTimeCode');
      }

      // Do not include create account step if:
      // - Create client/patient ff is not enabled
      // - Or if selected request type is not direct booking
      // - Or if the widget is being rendered in the pet portal
      const shouldSkipCreateAccountStep =
        !canCreateClientPatient || request?.requestType !== WidgetRequestType.DirectBooking || isPetPortal;
      if (shouldSkipCreateAccountStep) {
        formSteps = formSteps.filter((step) => step !== 'CreateAccount');
      }

      if (request) {
        GATrack(`${WidgetRequestTypeEventPrefix[request?.requestType]}_${GA4Events.START}`, { clinicId });
      }

      return {
        selectedRequest: request,
        requestSteps: formSteps,
        isOpen: true,
      };
    });

    window.parent.postMessage(
      {
        message: WindowMessageEvent.SelectRequestType,
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  },
  goToNextStep: (): void => {
    set(({ selectedRequest, requestSteps, currentStepIndex, clinicId }) => {
      const requestType = selectedRequest?.requestType;
      const newStepIndex = currentStepIndex + 1;

      if (requestType) {
        GATrack(`${WidgetRequestTypeEventPrefix[requestType]}_${GA4Events.VIEW}_${requestSteps[newStepIndex]}`, {
          clinicId: clinicId,
        });
      }

      return {
        currentStepIndex: newStepIndex,
      };
    });
  },
  goToStep: (stepName: RequestStep): void => {
    set(({ selectedRequest, requestSteps, clinicId }) => {
      const requestType = selectedRequest?.requestType;
      const selectedStepIndex = requestSteps.findIndex((step) => step === stepName);

      if (requestType) {
        GATrack(`${WidgetRequestTypeEventPrefix[requestType]}_${GA4Events.VIEW}_${requestSteps[selectedStepIndex]}`, {
          clinicId: clinicId,
        });
      }

      return {
        currentStepIndex: selectedStepIndex,
      };
    });
  },
  goToPreviousStep: (): void => {
    set(
      ({
        currentStepIndex,
        requestSteps,
        selectedRequest,
        clinicId,
        authentication: { emailAddress, phoneNumber },
      }) => {
        const newStepIsSelectRequest = requestSteps[currentStepIndex - 1] === 'SelectRequest';

        if (selectedRequest) {
          GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest?.requestType]}_${GA4Events.BACK_CLICK}`, {
            clinicId,
          });
        }

        return {
          currentStepIndex: currentStepIndex - 1,
          // If navigating back from contact info step reset selected request and auth state except email or phone
          ...(newStepIsSelectRequest && {
            selectedRequest: null,
            clinicPetParent: null,
            teletriage: defaultTeletriageState,
            authentication: { ...defaultAuthState, emailAddress, phoneNumber },
          }),
        };
      },
    );
  },
  goBackToMainMenu: (): void => {
    set(({ authentication: { phoneNumber, emailAddress } }) => {
      return {
        clinicPetParent: null,
        teletriage: defaultTeletriageState,
        authentication: {
          ...defaultAuthState,
          phoneNumber,
          emailAddress,
        },
        selectedRequest: null,
        currentStepIndex: 0,
      };
    });
  },
  updateWidgetAuthState: (newAuthState: Partial<AuthenticationState>): void => {
    set((state) => ({
      authentication: {
        ...state.authentication,
        ...newAuthState,
      },
    }));
  },
  updateDbState: (newDbState: Partial<DirectBookingState>): void => {
    set((state) => ({
      directBooking: {
        ...state.directBooking,
        ...newDbState,
      },
    }));
  },
  updateTeletriageState: (newTeletriageState: Partial<TeletriageState>): void => {
    set((state) => ({
      teletriage: {
        ...state.teletriage,
        ...newTeletriageState,
      },
    }));
  },
  handleCreateNewDbUser: (): void => {
    set(({ authentication, requestSteps, clinicId }) => {
      //  If new db user we don't need to send one time code
      const newSteps = requestSteps.filter((step) => step !== 'OneTimeCode');
      let createAccountIndex = newSteps.findIndex((step) => step === 'CreateAccount');

      // If CreateAccount step does not exist, add it
      if (createAccountIndex < 0) {
        newSteps.splice(2, 0, 'CreateAccount');
        createAccountIndex = 2;
      }

      GATrack(GA4Events.DIRECT_BOOKING_NEW_PROFILE_START, {
        clinicId: clinicId,
      });

      return {
        currentStepIndex: createAccountIndex,
        requestSteps: newSteps,
        authentication: {
          ...authentication,
          isNewClient: true,
        },
      };
    });
  },
  handleNoPetsNotAllowed: (): void => {
    set(({ selectedRequest, authentication, clinicId }) => {
      if (selectedRequest?.requestType) {
        GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest?.requestType]}_${GA4Events.USER_NOT_ALLOWED}_no_pets`, {
          clinicId,
        });
      }

      return {
        currentStepIndex: 0,
        selectedRequest: null,
        authentication: {
          ...authentication,
          showAuthenticationAlert: true,
          hasNoPets: true,
        },
      };
    });
  },
  handleNewUserNotAllowed: (): void => {
    set(({ selectedRequest, clinicId, authentication }) => {
      if (selectedRequest?.requestType) {
        GATrack(
          `${WidgetRequestTypeEventPrefix[selectedRequest?.requestType]}_${GA4Events.USER_NOT_ALLOWED}_new_user`,
          {
            clinicId,
          },
        );
      }

      return {
        currentStepIndex: 0,
        selectedRequest: null,
        authentication: {
          ...authentication,
          showAuthenticationAlert: true,
          isNewClient: true,
        },
      };
    });
  },
  handleNewUser: (): void => {
    set(({ selectedRequest, authentication, currentStepIndex, clinicId }) => {
      if (selectedRequest?.requestType) {
        GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest?.requestType]}_${GA4Events.NEW_USER}`, {
          clinicId,
        });
      }

      return {
        authentication: {
          ...authentication,
          isNewClient: true,
        },
        currentStepIndex: currentStepIndex + 1,
      };
    });
  },
  handleInactiveUser: (): void => {
    set(({ selectedRequest, clinicId, authentication }) => {
      if (selectedRequest?.requestType) {
        GATrack(
          `${WidgetRequestTypeEventPrefix[selectedRequest?.requestType]}_${GA4Events.USER_NOT_ALLOWED}_inactive_user`,
          {
            clinicId,
          },
        );
      }

      return {
        authentication: {
          ...authentication,
          isPetParentInactive: true,
        },
      };
    });
  },
  handleAuthenticatedUser: (): void => {
    set((state) => {
      const {
        currentStepIndex,
        clinicPetParent,
        authentication,
        directBooking: { canCreateClientPatient, isOtcEnabled },
        authentication: { emailAddress, phoneNumber },
        selectedRequest,
        requestSteps,
        clinicId,
      } = state;

      let newStepIndex = currentStepIndex + 1;
      let newSteps = requestSteps;
      const isDirectBooking = selectedRequest?.requestType === WidgetRequestType.DirectBooking;

      if (isDirectBooking) {
        GATrack(
          !!clinicPetParent ? GA4Events.DIRECT_BOOKING_CLIENT_ID_SUCCESS : GA4Events.DIRECT_BOOKING_CLIENT_ID_FAILURE,
          { clinicId, phoneNumber, emailAddress },
        );

        // If user already exists and otc is not enabled, skip create account step and navigate to appt details step
        if (canCreateClientPatient) {
          newSteps = requestSteps.filter((step) => step !== 'CreateAccount');

          if (!isOtcEnabled) {
            newStepIndex = newSteps.findIndex((step) => step === 'AppointmentDetails');
          }
        }
      }

      if (selectedRequest) {
        GATrack(
          `${WidgetRequestTypeEventPrefix[selectedRequest.requestType]}_${GA4Events.VIEW}_${
            requestSteps[newStepIndex]
          }`,
          {
            clinicId,
          },
        );
      }

      return {
        currentStepIndex: newStepIndex,
        requestSteps: newSteps,
        authentication: {
          ...authentication,
          hasNoPets: clinicPetParent ? clinicPetParent.pets.length <= 0 : false,
          isNewClient: false,
        },
      };
    });
  },
  handleChangeUser: (): void => {
    set(({ selectedRequest, clinicId, requestSteps, authentication: { phoneNumber, emailAddress } }) => {
      const contactInfoStepIndex = requestSteps.findIndex((step) => step === 'ContactInfo');

      if (selectedRequest) {
        GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest.requestType]}_${GA4Events.CHANGE_USER}`, {
          clinicId,
        });
      }

      return {
        currentStepIndex: contactInfoStepIndex,
        clinicPetParent: null,
        teletriage: defaultTeletriageState,
        authentication: { ...defaultAuthState, phoneNumber, emailAddress },
      };
    });
  },
  handleUnenrolledUser: (membershipStatus: MembershipStatus): void => {
    set(({ teletriage, currentStepIndex, authentication, clinicId, clinicPetParent }) => {
      GATrack(`${WidgetRequestTypeEventPrefix['Teletriage']}_${GA4Events.VIEW}`, {
        clinicId,
        name: clinicPetParent?.firstName,
        lastName: clinicPetParent?.lastName,
        petParentId: clinicPetParent?.id,
        isEnrolled: false,
        hasPets: !!clinicPetParent?.pets.length,
      });

      return {
        teletriage: {
          ...teletriage,
          membershipStatus,
        },
        authentication: {
          ...authentication,
          isPetParentInactive: membershipStatus === 'isInactive',
        },
        currentStepIndex: currentStepIndex + 1,
      };
    });
  },
  handleEnrolledUser: (): void => {
    set(({ clinicId, clinicPetParent, teletriage, requestSteps }) => {
      const newSteps = requestSteps.filter((step) => step !== 'InvalidMembershipStatus' && step !== 'LinkSent');
      const requestFormStepIndex = newSteps.findIndex((step) => step === 'RequestForm');

      GATrack(`${WidgetRequestTypeEventPrefix['Teletriage']}_${GA4Events.VIEW}`, {
        clinicId,
        name: clinicPetParent?.firstName,
        lastName: clinicPetParent?.lastName,
        petParentId: clinicPetParent?.id,
        isEnrolled: false,
        hasPets: !!clinicPetParent?.pets.length,
      });

      return {
        teletriage: {
          ...teletriage,
          membershipState: 'isEnrolled',
        },
        currentStepIndex: requestFormStepIndex,
        requestSteps: newSteps,
      };
    });
  },
  updateWidgetState: (newState: Partial<State>): void => {
    set((state) => ({ ...state, ...newState }));
  },
  reset: (): void => {
    set(initialState);
  },
}));
