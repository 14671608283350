import React, { useMemo } from 'react';
import { Select, SelectOptionProps, SelectProps } from '@televet/kibble-ui/build/components/Select';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { useWidgetStore } from 'state/useWidgetStore';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { EnrollmentStatus } from 'shared/types/graphql';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { env } from 'env';

export interface SelectedPet {
  id: string;
  name: string;
  isEnrolled: boolean;
}

interface PetPickerProps {
  selectedPet?: SelectedPet;
  handlePetSelection: (pet: SelectedPet) => void;
  selectProps?: SelectProps;
  isTeletriageRequest?: boolean;
}

export const PetPicker = ({
  selectedPet,
  handlePetSelection,
  selectProps,
  isTeletriageRequest = false,
}: PetPickerProps): JSX.Element => {
  const { clinicPetParent } = useWidgetStore((state) => state);

  const pets = useMemo(() => clinicPetParent?.pets, [clinicPetParent?.pets]);

  const options: SelectOptionProps[] = useMemo(
    () =>
      pets?.map((pet) => {
        const enrollments = pet.organizationPet?.carePlanEnrollments;
        const activeEnrollment = enrollments?.find((enrollment) => enrollment.status === EnrollmentStatus.Active);
        const isEnrolled = !!activeEnrollment;

        return {
          label: (
            <Flex alignItems="center">
              <Text>{pet.name}</Text>
              {isEnrolled && (
                <Flex color="yellow.400" ml={1} mt={0}>
                  <Icon name="starCircle" size="sm" color="currentColor" />
                </Flex>
              )}
            </Flex>
          ),
          value: pet.id,
          onSelect: (): void => handlePetSelection({ id: pet.id, name: pet.name, isEnrolled }),
        };
      }) || [],
    [pets, handlePetSelection],
  );

  const handleAlertClick = (): void => {
    window.open(`${env.REACT_APP_PET_WEB_URL}/?forward-to=careplan`, '_blank');
  };

  return (
    <>
      <Select
        {...selectProps}
        options={options}
        placeholder="Select Pet"
        listProps={{ maxH: '180px' }}
        value={selectedPet?.id}
      />
      {isTeletriageRequest && !!selectedPet && !selectedPet.isEnrolled && (
        <Alert
          status={selectProps?.isInvalid ? 'error' : 'info'}
          title="This pet is not a Care member."
          description="24/7 Live Chat is only available for pets under a Care membership"
          buttonProps={{
            buttonText: 'Sign Up Now',
            buttonPosition: 'bottomLeft',
            onClick: handleAlertClick,
          }}
          hideCloseButton={true}
          mt={3}
        />
      )}
    </>
  );
};
